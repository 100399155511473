export const PROJECT_ACTIVE = [
  { text: 'Project', value: 'name' },
  { text: 'Member', value: 'members' },
  // { text: 'Trackable by me', value: 'trackable' },
];

export const PROJECT_ARCHIVED = [
  { text: 'Project', value: 'name' },
  { text: 'Member', value: 'members' },
  // { text: 'Trackable by me', value: 'trackable' },
];
